import { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Trans, t, msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useForm, useFieldArray, FormProvider } from 'react-hook-form'
import {
  Alert,
  Avatar,
  Box,
  Collapse,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Slide,
  Snackbar,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material'
// import { AddLocationOutlined, Close, EditLocationOutlined, Logout } from '@mui/icons-material'
import { Close } from '@mui/icons-material'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'
import AddLocationIcon from '@mui/icons-material/AddLocation'
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import DesktopNav from '../components/desktop/DesktopNav'
import NaviTreeView from '../components/forms/NaviTree'
import MobileTopNav from '../components/mobile/MobileTopNav'
import MobileBottomNav from '../components/mobile/MobileBottomNav'
import useHttp from '../hooks/useHttp'
import useLogout from '../hooks/useLogout'
import FormContainer from '../components/forms/FormContainer'
import MobileLayersPanel from '../components/mobile/MobileLayers'
import Legend from '../components/Legend'
import FullPageProgress from '../components/FullPageProgress'
// import MapLibre from '../components/maps/MapLibre'
import MapLibre from '../components/maps/MapLibre_REACT'
import dayjs from 'dayjs'
import LocalPolice from '@mui/icons-material/LocalPolice'
import LocalHospital from '@mui/icons-material/LocalHospital'
import AccountBalance from '@mui/icons-material/AccountBalance'
import History from './History'
import useOnlineStatus from '../hooks/useOnlineStatus'
import { saveToIndexedDB } from '../utils'
import { ThemeContext } from '../App'

// eslint-disable-next-line no-restricted-globals
const addCrashUrl = new URL('/api/crash/add', location)
// eslint-disable-next-line no-restricted-globals
const modifyCrashUrl = new URL('/api/crash/modify', location)
// eslint-disable-next-line no-restricted-globals
const deleteCrashUrl = new URL('/api/crash/delete', location)
// eslint-disable-next-line no-restricted-globals
const getCrashBaseUrl = new URL('/api/crash', location)
// eslint-disable-next-line no-restricted-globals
const allCrashesBaseUrl = new URL('/api/crash/all', location)
// eslint-disable-next-line no-restricted-globals
const draftUrl = new URL('/api/draft', location)
// eslint-disable-next-line no-restricted-globals
const allDraftsUrl = new URL('/api/drafts', location)
// eslint-disable-next-line no-restricted-globals
const initUrl = new URL('/api/init', location)
// eslint-disable-next-line no-restricted-globals
const userBaseUrl = new URL('/api/user', location)

function SlideTransition (props) {
  return <Slide {...props} direction="up" />
}

function composeFormList (formObj) {
  let formList = []
  Object.keys(formObj).forEach(key => {
    if (Array.isArray(formObj[key])) {
      formObj[key].forEach((item, i) => {
        formList = [...formList, `${key}.${i}`]
        Object.keys(item).forEach(elem => {
          if (Array.isArray(item[elem])) {
            item[elem].forEach((_, j) => {
              formList = [...formList, `${key}.${i}.${elem}.${j}`]
            })
          }
        })
      })
    } else formList = [...formList, key]
  })

  return formList
}

export default function Home ({ position, isMobile, handledCrash }) {
  const isOnline = useOnlineStatus()
  const location = useLocation()
  const token = location.state?.token || null
  const init = useMemo(() => location.state?.init || {}, [location])
  const logout = useLogout()
  const theme = useTheme()
  const drawerWidth = theme.mixins.drawerWidth
  const { _ } = useLingui()
  const methods = useForm({
    defaultValues: useMemo(elements => elements, [])
  })
  const unitsFieldArrayMethods = useFieldArray({
    control            : methods.control
    , name             : 'units'
    , shouldUnregister : true
  })
  const peopleFieldArrayMethods = useFieldArray({
    control            : methods.control
    , name             : 'people'
    , shouldUnregister : true
  })
  const { isLoading, error, sendRequest } = useHttp()
  const [user, setUser] = useState({})
  const [totCrashes, setTotCrashes] = useState({ total: 0, fatal: 0 })
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
  const [isAdminDialogOpen, setIsAdminDialogOpen] = useState(false)
  const [isPositioning, setIsPositioning] = useState(false)
  const [isPositionSet, setIsPositionSet] = useState(false)
  const [isFormOpen, setIsFormOpen] = useState(false)
  const isHistoryOpen = location.state?.isHistoryOpen ?? false
  const [isDraftsMenuOpen, setIsDraftsMenuOpen] = useState(false)
  const [allDrafts, setAllDrafts] = useState([])
  const [allAutoDrafts, setAllAutoDrafts] = useState([])
  const [allCrashes, setAllCrashes] = useState({})
  const [showCrashes, setShowCrashes] = useState(false)
  const [isMerged, setIsMerged] = useState(false)
  const [isUnmerged, setIsUnmerged] = useState(false)
  const [message, setMessage] = useState('')
  const [openMobileLayers, setOpenMobileLayers] = useState(false)
  const [showLegend, setShowLegend] = useState(false)
  // const [showHeatMap, setShowHeatMap] = useState(false)
  const [heatMapData, setHeatMapData] = useState(null)
  const [formNumber, setFormNumber] = useState(0)
  const [formModel, setFormModel] = useState({})
  const [formList, setFormList] = useState([])
  const [defaults, setDefaults] = useState({})
  const [initFormObj, setInitFormObj] = useState({})
  const [images, setImages] = useState([])
  const [newCrash, setNewCrash] = useState(null)
  // const [crashInfo, setCrashInfo] = useState({})
  const [reporterData, setReporterData] = useState({})
  const submitRef = useRef()
  const mapRef = useRef()
  const { toggleTheme } = useContext(ThemeContext)

  const [visibility, setVisibility] = useState({
    heatmap: false
    // , riskMap   : false // TODO
  })

  const [layersVisibility, setLayersVisibility] = useReducer(
    (state, updates) => ({ ...state, ...updates }
    ), {})

  useEffect(() => {
    // Convert true/false to "visible"/"none"
    const visibilityState = Object.fromEntries(
      Object.entries(visibility).map(([k, v]) => [k, v ? 'visible' : 'none'])
    )
    setLayersVisibility(visibilityState)
  }, [visibility])

  const onVisibilityChange = (name, value) => {
    setVisibility({ ...visibility, [name]: value })
  }

  const setFormModelConfiguration = useCallback(
    (configuration, defaults) => {
      setFormModel(configuration)
      const initForms = Object.keys(configuration)
        .filter(key => configuration[key].hierarchy === 0)
        .sort((a, b) => configuration[a].position - configuration[b].position)
      console.log('init forms', initForms)

      setFormList(() => initForms)
      console.log('DEFAULTS', defaults)
      setDefaults(defaults)
      let formObj = {}
      initForms.forEach(
        form => (formObj = { ...formObj, [form]: defaults[form] })
      )
      // Theese caches and sets the defaults of the form
      setInitFormObj(formObj)
      methods.reset(formObj) // {defaults.crash, road: defaults.road }
    },
    [methods]
  )

  // Fetch of init
  useEffect(() => {
    if (!token) return logout()
    const { configuration, defaults, user, totalCrashes } = init
    setUser(user)
    toggleTheme(user.theme)
    setTotCrashes(totalCrashes)
    // if (user.type !== 1) setFormModelConfiguration(configuration, defaults)
    setFormModelConfiguration(configuration, defaults)
  }, [token, init, logout, setFormModelConfiguration, toggleTheme])

  const getNewInit = useCallback(async organization => {
    const newInitUrl = new URL(initUrl)
    newInitUrl.searchParams.set('organization', organization)
    await sendRequest({
      token
      , url           : newInitUrl
      , manageResData : res => {
        console.log('NEW INIT', res)
        const { error: initError, error_code, result, ...newInit } = res // { configuration, defaults, user, totalCrashes }
        if (!initError) {
          setUser(old => ({
            ...old
            , doAsOrganization      : newInit.user.organization
            , doAsBasicOrganization : newInit.user.basic_organization
          }))
          setFormModelConfiguration(newInit.configuration, newInit.defaults)
        }
      }
    })
  }, [setFormModelConfiguration, sendRequest, token])

  const getAllDrafts = useCallback(() => {
    if (!token) return logout()
    if (!isAddDialogOpen) return
    sendRequest({
      token
      , url           : allDraftsUrl
      , manageResData : res => {
        setAllAutoDrafts(res.drafts.filter(draft => draft.endsWith('-auto')).filter((_, i) => i < 10))
        setAllDrafts(res.drafts.filter(draft => !draft.endsWith('-auto')).filter((_, i) => i < 5))
      }
    })
  }, [token, isAddDialogOpen, sendRequest, logout])

  const getDraft = useCallback(
    draftId => {
      if (!token) return logout()
      const getDraftUrl = new URL(draftUrl)
      getDraftUrl.pathname += `/${draftId}`
      sendRequest({
        token
        , url           : getDraftUrl
        , manageResData : res => {
          const data = res.data
          if (Object.prototype.hasOwnProperty.call(data, 'crash_id')) { delete data.crash_id }
          if (Object.prototype.hasOwnProperty.call(data, 'isDraft')) { delete data.isDraft }
          if (Object.prototype.hasOwnProperty.call(data, 'isFinal')) { delete data.isFinal }
          const draftFormsList = composeFormList(data)
          setFormList(draftFormsList)
          methods.reset(data) // Resets form
          closeDialog()
          setNewCrash(data.crash)
          // setCrashInfo(data.crash)
          setImages(data.crash.images)
          setIsFormOpen(true)
        }
      })
    },
    [token, sendRequest, logout, methods]
  )

  const getCrash = useCallback(
    async (crashId, organization, reporterId) => {
      if (!token) return logout()
      // if (init.user.type > 0 && organization > 0) await getNewInit(organization)
      if (reporterId) {
        const url = new URL(userBaseUrl)
        url.searchParams.set('id', reporterId)
        await sendRequest({
          token
          , url
          , manageResData: res => {
            setReporterData(res.user)
          }
        })
      }
      const getCrashUrl = new URL(getCrashBaseUrl)
      getCrashUrl.pathname += `/${crashId}`
      sendRequest({
        token
        , url           : getCrashUrl
        , manageResData : async res => {
          const crash = res.crash
          if (init.user.type > 0 && !crash.crash.merge_date) {
            await getNewInit(organization || crash.crash.organization)
          }
          const crashFormsList = composeFormList(crash)
          setFormList(crashFormsList)
          methods.reset(crash) // Resets form
          // setCrashInfo(crash.crash)
          setNewCrash(crash.crash)
          setImages(crash.crash.images)
          setIsFormOpen(true)
        }
      })
    },
    [token, sendRequest, logout, methods, getNewInit, init.user.type]
  )

  useEffect(() => {
    if (Object.keys(handledCrash).length) {
      const { id, organization: orgName, reporterId } = handledCrash
      const organization = init.organizations.find(org => org.label === orgName)?.id
      getCrash(id, organization, reporterId)
    }
  }, [handledCrash, getCrash, init.organizations])

  // Add crash flow controls
  const handleSetNewCrash = crash => {
    setNewCrash(crash)
  }

  const openDialog = () => {
    setIsAddDialogOpen(true)
  }

  const closeDialog = () => {
    setIsDraftsMenuOpen(false)
    setIsAddDialogOpen(false)
  }

  const openAdminDialog = () => {
    setIsAdminDialogOpen(true)
  }

  const closeAdminDialog = () => {
    setIsAdminDialogOpen(false)
  }

  const handlePositioningState = state => {
    setIsPositioning(state)
  }

  const handleIsPositionSetState = state => {
    setIsPositionSet(state)
  }

  const handleFormOpen = state => {
    setIsFormOpen(state)
  }

  const handleCoordinates = coords => {
    methods.setValue('crash.Latitude', coords.lat)
    methods.setValue('crash.Longitude', coords.lng)
  }

  const handlePicture = (picture, removeIndex) => {
    if (removeIndex !== undefined) {
      setImages(old => old.filter((o, i) => i !== removeIndex))
    } else {
      setImages(old => [...old, picture])
    }
  }

  const handleCrashToEdit = (allCrashes, crashId) => {
    const allCrashesFiltered = { reduced: allCrashes.reduced.filter(ac => ac['Crash id'] !== crashId) }
    setAllCrashes(allCrashesFiltered)
  }

  // Map overlays controls
  const toggleMobileLayers = bool =>
    setOpenMobileLayers(typeof bool !== 'boolean' ? !openMobileLayers : bool)

  const getCrashes = useCallback(
    async (format = 'reduced', merged) => {
      const allCrashesUrl = new URL(allCrashesBaseUrl)
      allCrashesUrl.searchParams.set('format', format)
      if (merged !== undefined) allCrashesUrl.searchParams.append('merged', merged)
      await sendRequest({
        token
        , url           : allCrashesUrl
        , manageResData : res => {
          if (format === 'reduced') {
            setAllCrashes(() => ({ reduced: res.crashes }))
          }
          if (format === 'heat_map') {
            setHeatMapData(() => ({
              type     : res.type
              , features : res.features.map(feat => ({
                ...feat
                , geometry: {
                  ...feat.geometry
                  , coordinates: [
                    feat.geometry.coordinates[0]
                    , feat.geometry.coordinates[1]
                  ]
                }
              }))
            }))
          }
        }
        // setAllCrashes(() => ({
        //   [format]:
        //     format === 'reduced'
        //       ? res.crashes
        //       : {
        //           type     : res.type
        //           , features : res.features.map(feat => ({
        //             ...feat
        //             , geometry: {
        //               ...feat.geometry
        //               , coordinates: [
        //                 feat.geometry.coordinates[0]
        //                 , feat.geometry.coordinates[1]
        //               ]
        //             }
        //           }))
        //         }
        // }))
      })
    },
    [token, sendRequest]
  )

  useEffect(() => {
    const mergeParam = () => {
      if (isMerged && isUnmerged) return undefined
      if (isMerged) return 1
      if (isUnmerged) return 0
    }

    if (isMerged || isUnmerged) getCrashes(undefined, mergeParam())
  }, [getCrashes, isMerged, isUnmerged])

  useEffect(() => {
    setShowCrashes(isMerged || isUnmerged)
  }, [isMerged, isUnmerged])

  // Form navigation controls
  const handleSetFormNumber = number => {
    if (!isNaN(number)) return setFormNumber(number)
    if (number === '+') return setFormNumber(formNumber + 1)
    if (number === '-' && formNumber - 1 >= 0) { return setFormNumber(formNumber - 1) }
    setFormNumber(0)
  }

  const handleSetFormNumberFromList = formName => {
    setFormNumber(formList.indexOf(formName))
  }

  const getFormType = useCallback(
    number => {
      let type
      const organization =
        user?.organization > 0 ? user.organization : user?.doAsOrganization
      if ((organization !== 2 && number < 3) || number === 0) {
        type = Object.keys(formModel).sort(
          (a, b) => formModel[a].position - formModel[b].position
        )[number]
      } else {
        type = formList[number]?.split('.').slice(-2, -1)[0]
      }
      return type
    },
    [formList, formModel, user.organization, user.doAsOrganization]
  )

  const getFormDisplayName = useCallback(
    number => {
      return formModel[getFormType(number)]?.displayName
    },
    [formModel, getFormType]
  )

  const filterObject = object => {
    const { isDraft, isFinal, ...rest } = object
    return rest
  }

  // This appends a new instance of repeated form
  const handleAppend = (formType, nestElem, append) => {
    console.log('FORM LIST', formList)
    console.log(defaults.units, defaults.people)
    if (formType === 'units') {
      const lastIndex = unitsFieldArrayMethods.fields.length
      unitsFieldArrayMethods.append(
        defaults.units.map(elem => ({ ...elem, 'Unit id': lastIndex + 1 }))
      )
      setFormList(old => [...old, `${formType}.${lastIndex}`])
      setFormNumber(formList.length)
      return
    }
    if (formType === 'people') {
      const lastIndex = peopleFieldArrayMethods.fields.length
      peopleFieldArrayMethods.append(
        defaults.people.map(elem => ({
          ...elem
          , 'Person id': lastIndex + 1
        }))
      )
      setFormList(old => [...old, `${formType}.${lastIndex}`])
      setFormNumber(formList.length)
      return
    }
    if (formType === 'unitPeople') {
      console.log(nestElem, methods.getValues())
      nestElem = nestElem.split('.').slice(0, 2).join('.')
      const nestIndex = methods.getValues(nestElem)?.people?.length || 0
      const newNestedName = `${nestElem}.people.${nestIndex}`
      append(
        defaults.people.map(elem => ({
          ...elem
          , 'Person id' : nestIndex + 1
          , 'Unit id'   : Number.parseInt(nestElem.split('.')[1]) + 1
        }))
      )
      setFormList(old => [...old, newNestedName])
      setFormNumber(formList.length)
    }
  }

  const handleRemove = (formType, form, remove) => {
    const { number, name } = form
    setFormList(old => old.filter(o => o !== name))
    if (formType === 'units') {
      const fieldArrayIndex = /(?<=^units\.)\d+/.exec(name)[0]
      unitsFieldArrayMethods.remove(fieldArrayIndex)
      setFormList(old => old.filter(o => !o.includes(name)))
    }
    if (formType === 'people') {
      const fieldArrayIndex = /(?<=^people\.)\d+/.exec(name)[0]
      peopleFieldArrayMethods.remove(fieldArrayIndex)
    }
    if (formType === 'unitPeople' && remove) {
      methods.watch()
      // const fieldArrayIndex = Number.parseInt(/(?<=^units\.)\d+/.exec(name)[0])
      const peopleArrayIndex = Number.parseInt(/(?<=people\.)\d+/.exec(name)[0])
      remove(peopleArrayIndex)
    }
    setFormNumber(number - 1)
  }

  // Get form data and post it
  const handleCrashSubmit = async data => {
    console.log(data, images)
    if (data.crash.merge_date) return
    if (data.isModifying && !data.isFinal) return
    let url = draftUrl
    if (!data.isDraft && data.isFinal) url = addCrashUrl
    if (data.isModifying && data.isFinal) url = modifyCrashUrl

    let newData = {
      ...data
      , crash_id : data.crash['Crash id']
      , crash    : {
        ...data.crash
        , images
        , organization:
          user.organization === -1 ? user.doAsOrganization : user.organization
      }
    }

    if (!data.isFinal) {
      newData = { ...newData, draft_suffix: 'auto' }
    }

    // if (!isOnline) {
    // // Store request in IndexedDB when offline
    //   await saveToIndexedDB({ url, method: 'POST', body: newData })
    //   // Register background sync
    //   if ('serviceWorker' in navigator && 'SyncManager' in window) {
    //     navigator.serviceWorker.ready.then(sw => {
    //       sw.sync.register('sync-crash-submissions')
    //     })
    //   }
    //   return
    // }

    sendRequest({
      token
      , url
      , httpMethod    : 'POST'
      , httpBody      : newData
      , manageResData : async res => {
        if (data.isFinal) {
          setIsFormOpen(false)
          setIsPositioning(false)
          setIsPositionSet(false)
          delete data.isFinal
          methods.reset(initFormObj)
          setFormNumber(0)
          setImages(() => [])
          setNewCrash(null)
          // const newMarkers = mapRef.current.getElementsByClassName('new-markers')
          // Array.from(newMarkers).forEach(marker => marker.remove())
          await getCrashes('reduced', 0)
          setMessage(
            t`Crash saved ${data.isDraft ? 'as a draft' : `with id ${res.crash_id}`}`
          )
        }
      }
    })
  }

  const deleteCrash = crashId => {
    sendRequest({
      token
      , url           : deleteCrashUrl
      , httpMethod    : 'POST'
      , httpBody      : { crash_id: crashId }
      , manageResData : res => {
        setMessage(t`Crash id ${res.crash_id} has been deleted`)
      }
    })
  }

  const cancelCrash = crashId => {
    setIsPositionSet(false)
    setIsFormOpen(false)
    setIsPositioning(false)
    setNewCrash(null)
    // const newMarkers = mapRef.current.getElementsByClassName('new-markers')
    // console.log('HOME_NM', newMarkers)
    // Array.from(newMarkers).forEach(marker => marker.remove())
    methods.reset(initFormObj) // Resets form
    unitsFieldArrayMethods.remove()
    peopleFieldArrayMethods.remove()
    setImages(() => [])
    setFormNumber(0)
    if (crashId) deleteCrash(crashId)
    // if (isMerged) {
    //   getCrashes(undefined, 1)
    // }
    // if (isUnmerged) {
    //   getCrashes(undefined, 0)
    // }
  }

  const subPanelElements = [
    {
      label:
        isPositioning || isFormOpen
          ? t`Cancel`
          : t`Add crash`
      , icon:
        isPositioning || isFormOpen
          ? <Close color="primary" />
          : <AddLocationAltIcon color="primary" />
      , type          : 'button'
      , onMobile      : false
      , access        : [0, 1, 2]
      , baseOrgAccess : [1, 2, 3]
      , action:
        isPositioning || isFormOpen
          ? () => cancelCrash()
          : user.type === 1
            ? openAdminDialog
            : openDialog
    }
    , {
      label    : t`Show validated crashes`
      , icon     : null
      , type     : 'switch'
      , name     : 'crashes'
      , onMobile : true
      , access   : [0, 1, 2]
      , action   : () => setIsMerged(old => !old)
      // , action: async isChecked => {
      // if (isChecked) await getCrashes(undefined, 1)
      // onVisibilityChange('isMerged', isChecked)
      // setShowCrashes(isChecked)
      // }
    }
    , {
      label    : t`Show final crashes`
      , icon     : null
      , type     : 'switch'
      , name     : 'crashes'
      , onMobile : true
      , access   : [0, 1, 2]
      , action   : () => setIsUnmerged(old => !old)
      // , action: async isChecked => {
      // if (isChecked) await getCrashes()
      // onVisibilityChange('isUnmerged', isChecked)
      // setShowCrashes(isChecked)
      // }
    }
    , {
      label    : t`Show heatmap`
      , icon     : null
      , type     : 'switch'
      , name     : 'heatmap'
      , onMobile : true
      , access   : [0, 1, 2]
      , action   : async isChecked => {
        if (isChecked) await getCrashes('heat_map', 1)
        onVisibilityChange('heatmap', isChecked)
        // setShowHeatMap(isChecked)
      }
    }
    , {
      label    : t`Show legend`
      , icon     : null
      , type     : 'switch'
      , name     : 'legend'
      , onMobile : true
      , access   : [0, 1, 2]
      , action   : isChecked => {
        setShowLegend(isChecked)
      }
    }
  ]

  console.log('Form data', {
    number      : formNumber
    , name        : formList[formNumber]
    , type        : getFormType(formNumber)
    , displayName : getFormDisplayName(formNumber)
    , formList
  })

  return (
    <Box component="main">
      {isMobile
        ? (
        <>
          <MobileTopNav />
          <Toolbar />
        </>
          )
        : (
        <DesktopNav position={position} subPanelElements={subPanelElements} />
          )}
      {/* <Box position="absolute" zIndex="9999">
        <button
          onClick={() => {
            console.log('watch', methods?.watch())
          }}
        >
          watch me
        </button>
      </Box> */}
      <Box ml={isMobile ? 0 : `${drawerWidth}px`}>
        {isFormOpen && (
          <FormProvider {...methods} {...{ user }} {...{ handlePicture }}>
            <FormContainer
              token={token}
              open={isFormOpen}
              isLoading={isLoading}
              isMobile={isMobile}
              formModel={formModel}
              formList={formList}
              form={{
                number      : formNumber
                , name        : formList[formNumber]
                , type        : getFormType(formNumber)
                , displayName : getFormDisplayName(formNumber)
              }}
              submitRef={submitRef}
              handleFormOpen={handleFormOpen}
              handleSetFormNumber={handleSetFormNumber}
              handleCrashSubmit={handleCrashSubmit}
              handleAppend={handleAppend}
              handleRemove={handleRemove}
              images={images}
              reporterData={reporterData}
              cancelCrash={cancelCrash}
            ></FormContainer>

            {!isMobile && (
              <NaviTreeView
                data={filterObject(methods.getValues())}
                handleSetFormNumber={handleSetFormNumberFromList}
              />
            )}
          </FormProvider>
        )}
        {isHistoryOpen && (
          <History
            position={position}
            open={isHistoryOpen}
            isMobile={isMobile}
            getCrash={getCrash}
          />
        )}
        <Snackbar
          open={!!message}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          TransitionComponent={SlideTransition}
          onClose={(_, reason) => {
            if (reason === 'clickaway') {
              return
            }
            setMessage('')
          }}
        >
          <Alert variant="filled" severity="success" sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!error}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          TransitionComponent={SlideTransition}
          onClose={(_, reason) => {
            if (reason === 'clickaway') {
              return
            }
            setMessage('')
          }}
        >
          <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>

        <MapLibre
          isMobile={isMobile}
          isPositioning={isPositioning}
          isFormOpen={isFormOpen}
          isHistoryOpen={isHistoryOpen}
          showCrashes={showCrashes}
          allCrashes={allCrashes}
          crashesSummary={totCrashes}
          heatMapData={heatMapData}
          newCrash={newCrash}
          getCrash={getCrash}
          layersVisibility={layersVisibility}
          handlePositioningState={handlePositioningState}
          handleIsPositionSetState={handleIsPositionSetState}
          handleCoordinates={handleCoordinates}
          handleFormOpen={handleFormOpen}
          handleSetNewCrash={handleSetNewCrash}
          handleCrashToEdit={handleCrashToEdit}
        />
        <Dialog
          open={isAdminDialogOpen}
          onClose={() => {
            closeAdminDialog()
          }}
        >
          <DialogTitle>
            <Trans>Choose the organization</Trans>
          </DialogTitle>
          <List sx={{ pt: 0 }}>
            <ListItem disableGutters>
              <ListItemButton
                onClick={() => {
                  getNewInit(1)
                  closeAdminDialog()
                  openDialog()
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <LocalPolice />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={_(msg`Police`)} />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton
                onClick={() => {
                  getNewInit(2)
                  closeAdminDialog()
                  openDialog()
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <LocalHospital />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={_(msg`Healthcare`)} />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton
                onClick={() => {
                  getNewInit(3)
                  closeAdminDialog()
                  openDialog()
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <AccountBalance />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={_(msg`Insurance`)} />
              </ListItemButton>
            </ListItem>
          </List>
        </Dialog>
        <Dialog open={isAddDialogOpen} onClose={closeDialog}>
          <DialogTitle>
            <Trans>What do you want to do?</Trans>
          </DialogTitle>
          <List sx={{ pt: 0 }}>
            {(user.organization === -1
              ? user.doAsOrganization
              : user.organization) === 2 && (
              <ListItem disableGutters>
                <ListItemButton
                  onClick={() => {
                    closeDialog()
                    handleCoordinates({ lat: 0.0, lng: 0.0 })
                    handleFormOpen(true)
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <AddCircleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={_(msg`Add new crash without coordinates`)}
                  />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem disableGutters>
              <ListItemButton
                onClick={() => {
                  closeDialog()
                  handlePositioningState(true)
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <AddLocationAltIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={_(msg`Add new crash on map`)} />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton
                onClick={() => {
                  closeDialog()
                  handleFormOpen(true)
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <AddLocationIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={_(msg`Add new crash at your current location`)}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton
                onClick={() => {
                  setIsDraftsMenuOpen(true)
                  getAllDrafts()
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <EditLocationAltIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={_(msg` Edit a crash from drafts`)} />
              </ListItemButton>
            </ListItem>
            <Collapse in={isDraftsMenuOpen} timeout="auto" unmountOnExit>
              <Divider />
              <List component="div" disablePadding>
                {allDrafts.length
                  ? (
                      allDrafts.map(draft => {
                        const label = draft.replace(/^\d_/, '').replace('p', '+')
                        return (
                      <ListItemButton
                        key={draft}
                        sx={{ pl: 6 }}
                        onClick={() => getDraft(draft)}
                      >
                        <ListItemText
                          primary={dayjs(
                            label,
                            'YYYYMMDDThhmmssSS'
                          ).toLocaleString()}
                        />
                      </ListItemButton>
                        )
                      })
                    )
                  : (
                  <ListItemText sx={{ pl: 6 }} primary={t`No drafts saved`} />
                    )}
              </List>
              <Divider />
              <Typography pl={6} mt={1} mb={1}><Trans>Auto-saved drafts</Trans></Typography>
              <Divider />
              <List component="div" disablePadding>
                {allAutoDrafts.length
                  ? (
                      allAutoDrafts.map(draft => {
                        const label = draft.replace(/^\d_/, '').replace('p', '+')
                        return (
                      <ListItemButton
                        key={draft}
                        sx={{ pl: 6 }}
                        onClick={() => getDraft(draft)}
                      >
                        <ListItemText
                          primary={dayjs(
                            label,
                            'YYYYMMDDThhmmssSS'
                          ).toLocaleString()}
                        />
                      </ListItemButton>
                        )
                      })
                    )
                  : (
                  <ListItemText sx={{ pl: 6 }} primary={t`No drafts saved`} />
                    )}
              </List>
            </Collapse>
          </List>
        </Dialog>
      </Box>
      {isMobile && !isFormOpen && isPositionSet &&
        <Box
          pt={1}
          width='100%'
          position='absolute'
          bottom={theme.mixins.toolbar.minHeight}
          height={theme.mixins.toolbar.minHeight}
          zIndex={theme.zIndex.drawer}
          sx={{
            backgroundColor        : '#10171f'
            , textAlign            : 'center'
            , borderTopLeftRadius  : '16px'
            , borderTopRightRadius : '16px'
          }}
        >
          <IconButton onClick={() => handleFormOpen(true)}>
            <KeyboardDoubleArrowUpIcon />
          </IconButton>
        </Box>
      }
      {isMobile && (
        <MobileBottomNav
          openDialog={openDialog}
          openAdminDialog={openAdminDialog}
          handleFormOpen={handleFormOpen}
          isPositioning={isPositioning}
          isFormOpen={isFormOpen}
          isPositionSet={isPositionSet}
          cancelCrash={cancelCrash}
          handlePositioningState={handlePositioningState}
          openMobileLayers={openMobileLayers}
          toggleMobileLayers={toggleMobileLayers}
        />
      )}
      {isMobile && (
        <MobileLayersPanel
          open={openMobileLayers}
          subPanelElements={subPanelElements}
        />
      )}
      <FullPageProgress
        isOpen={isLoading}
        isMobile={isMobile}
        isFormOpen={isFormOpen}
      />
      {showLegend && <Legend isMobile={isMobile} />}
    </Box>
  )
}
