import { useCallback, useMemo, useRef, useState } from 'react'
import {
  Box
  , Button, Chip
  , Slide
  , styled
  , Typography, useTheme
} from '@mui/material'
import { GeolocateControl, Layer, Map, Marker, NavigationControl, Popup as PopupComponent, ScaleControl, Source, useMap } from '@vis.gl/react-maplibre'
import { Popup } from 'maplibre-gl'
import StylesControl from './StylesControl'
import GeneralInfo from '../GeneralInfo'
import { SEVERITIES } from '../../constants'
import Copyright from '../Copyright'
import 'maplibre-gl/dist/maplibre-gl.css'
import { CustomPin } from './Pin'
import useWindowSize from '../../hooks/useWindowSize'
import { useLocation } from 'react-router-dom'
import { heatmapLayer } from './heatMapLayer'

const CENTER = [
  Number.parseFloat(process.env.REACT_APP_CENTER_LON)
  , Number.parseFloat(process.env.REACT_APP_CENTER_LAT)
] // LON, LAT Rome - This should be capital of the state
const ZOOM = 10
const PINSCALE = 0.75
const OFFSET = [0, -10]
const SPEED = 0.6

// Show cross on selecting position
const NewCrashSelector = ({ selectorTop, selectorLeft }) => {
  const windowSize = useWindowSize()

  const HLine = styled(Box)(({ theme }) => ({
    width             : windowSize.width - theme.mixins.drawerWidth
    , height          : 1
    , top             : selectorTop
    , position        : 'absolute'
    , backgroundColor : '#000'
    , pointerEvents   : 'none'
    , zIndex          : theme.zIndex.drawer - 1
  }))

  const VLine = styled(Box)(({ theme }) => ({
    width             : 1
    , height          : windowSize.height
    , left            : selectorLeft
    , position        : 'absolute'
    , backgroundColor : '#000'
    , pointerEvents   : 'none'
    , zIndex          : theme.zIndex.drawer - 1
  }))

  return (
    <>
      <HLine />
      <VLine />
    </>
  )
}

export default function MapLibre ({
  isMobile
  , isPositioning
  , isFormOpen
  , isHistoryOpen
  , showCrashes
  , allCrashes
  , crashesSummary
  , heatMapData
  , newCrash
  , getCrash
  , layersVisibility
  , handlePositioningState
  , handleIsPositionSetState
  , handleCoordinates
  , handleFormOpen
  , handleSetNewCrash
  , handleCrashToEdit
}) {
  const mapKey = process.env.REACT_APP_API_KEY
  const theme = useTheme()
  const barHeight = theme.mixins.toolbar.minHeight
  const formDrawerWidth = theme.mixins.formDrawerWidth
  const location = useLocation()
  const { init } = location.state
  const user = useMemo(() => init?.user, [init])
  const organizations = useMemo(() => init?.organizations, [init])
  const [mapStyle, setMapStyle] = useState('openstreetmap')
  const geoControlRef = useRef()
  const [selectorTop, setSelectorTop] = useState(0)
  const [selectorLeft, setselectorLeft] = useState(0)
  const transitionDuration = {
    enter   : theme.transitions.duration.enteringScreen
    , exit  : theme.transitions.duration.leavingScreen
  }
  const [popupInfo, setPopupInfo] = useState(null)

  const handleSetStyle = style => setMapStyle(style)

  const allCrashesMarkers = useMemo(
    () =>
      allCrashes?.reduced?.map(crash => {
        const fill = SEVERITIES()[crash['Crash severity']]?.fill || SEVERITIES()[0].fill
        return (
          <Marker
            key={`marker-${crash['Crash id']}`}
            longitude={crash.Longitude}
            latitude={crash.Latitude}
            offset={OFFSET}
            onClick={e => {
              /* If we let the click event propagates to the map, it will immediately close the popup
              with `closeOnClick: true` */
              e.originalEvent.stopPropagation()
              setPopupInfo(crash)
            }}
          >
            <CustomPin fill={fill} scale={PINSCALE} organization={!crash.merge_date && crash.organization} />
          </Marker>
        )
      }),
    [allCrashes]
  )

  const CrashPopup = ({ popupInfo }) => (
    <PopupComponent
      offset={OFFSET.map(v => v * 1.5)}
      longitude={Number(popupInfo.Longitude)}
      latitude={Number(popupInfo.Latitude)}
      onClose={() => setPopupInfo(null)}
    >
      <Box display='flex' flexDirection='column' alignItems='center' >
        <Typography variant='body2'>Report type:</Typography>
        <Typography variant='h5'>{popupInfo.merge_date ? 'Merged/Validated' : organizations?.find(org => org?.id === popupInfo?.organization)?.description}</Typography>
        <br />
        <Typography variant='body2'>Crash id:</Typography>
        <Typography fontWeight='bold' variant='body1'>{popupInfo['Crash id']}</Typography>
        {/* {!isEditing && (user.type === 1 || */}
        {(user.type === 1 ||
          (user.type === 2 && popupInfo.organization === user.organization && !isMobile) ||
          popupInfo.user_id === user.id) &&
          <Button
            variant='outlined'
            size='small'
            sx={{ mt: 1 }}
            onClick={() => {
              if (popupInfo.merge_date) {
                console.log('CLICK', popupInfo['Crash id'], popupInfo.organization)
                getCrash(popupInfo['Crash id'], popupInfo.organization)
              } else {
                console.log('CLICK', popupInfo['Crash id'], popupInfo.organization, popupInfo.user_id)
                getCrash(popupInfo['Crash id'], popupInfo.organization, popupInfo.user_id)
              }
              handleIsPositionSetState(true)
            }}
          >
            {popupInfo.merge_date ? 'VIEW' : 'EDIT'}
          </Button>
        }
      </Box>
    </PopupComponent>
  )

  const CrashMarker = ({ crash, handleCoordinates }) => {
    const popupHTML = `
<div style="display: flex; flex-direction: column; align-items: center">
  <p style="font-size: 0.875rem; font-family: 'Roboto', sans-serif; margin: 0; line-height: 1.43; letter-spacing: 0.01071em;">Report type:</p>
  <h5 style="font-size: 1.2rem; font-family: 'Roboto', sans-serif; font-weight: 400; margin: 0; line-height: 1.334; letter-spacing: 0em;">
    ${crash.merge_date ? 'Merged/Validated' : organizations?.find(org => org?.id === crash?.organization)?.description}
  </h5>
  <br />
  <p style="font-size: 0.875rem; font-family: 'Roboto', sans-serif; margin: 0; line-height: 1.43; letter-spacing: 0.01071em;">Crash id:</p>
  <p style="font-size: 1rem; font-family: 'Roboto', sans-serif; font-weight: 700; margin: 0; line-height: 1.5; letter-spacing: 0.00938em;">${crash['Crash id']}</p>
</div>`
    const { current: map } = useMap()
    const fill = SEVERITIES()[crash['Crash severity'] ?? 0].fill
    map.flyTo({ center: { lat: crash.Latitude, lng: crash.Longitude }, speed: SPEED })
    console.log(allCrashes)
    if (allCrashes?.reduced?.length && allCrashes?.reduced?.find(acr => acr['Crash id'] === crash['Crash id'])) {
      setPopupInfo(null)
      handleCrashToEdit(allCrashes, crash['Crash id'])
    }
    return (
      <Marker
        longitude={crash.Longitude}
        latitude={crash.Latitude}
        offset={OFFSET}
        draggable
        onDragEnd={e => handleCoordinates(e.lngLat)}
        popup={new Popup({ offset: OFFSET.map(v => v * 1.5) })
          .setHTML(popupHTML)
        }
      >
        <CustomPin
          fill={fill}
          scale={PINSCALE}
          organization={!crash.merge_date && crash.organization}
        />
      </Marker>
    )
  }

  const onClick = useCallback(e => {
    console.log('LNGLAT', e.lngLat, 'EEE', e)
    handleSetNewCrash({ isNew: true, Longitude: e.lngLat.lng, Latitude: e.lngLat.lat })
    handleCoordinates(e.lngLat)
    handleFormOpen(true)
    handlePositioningState(false)
    handleIsPositionSetState(true)
    // map.off('click', onclick) // Important: remove the listener
  }, [handlePositioningState, handleCoordinates, handleFormOpen, handleSetNewCrash, handleIsPositionSetState])

  return (
    <Box
      height={isMobile ? `calc(100vh - ${barHeight * 2}px)` : '100vh'}
      ml={!isMobile && isFormOpen ? `${formDrawerWidth}px` : 0}
      mr={!isMobile && isHistoryOpen ? `${formDrawerWidth}px` : 0}
    >
      <Map
        reuseMaps={true}
        initialViewState={{
          longitude : CENTER[0]
          , latitude  : CENTER[1]
          , zoom      : ZOOM
        }}
        style={{ width: '100%', height: '100%', cursor: isPositioning ? 'none' : 'grab' }}
        mapStyle={`https://api.maptiler.com/maps/${mapStyle}/style.json?key=${mapKey}`}
        attributionControl={false}
        onLoad={() => {
          // Activate as soon as the map is loaded
          geoControlRef.current?.trigger()
        }}
        onMouseMove={e => {
          if (!isMobile && isPositioning) {
            setSelectorTop(e.point.y)
            setselectorLeft(isFormOpen ? e.point.x - formDrawerWidth : e.point.x)
          }
        }}
        cursor={!isMobile && isPositioning ? 'none' : 'Grab'}
        onClick={e => isPositioning && onClick(e) }
      >
        <NavigationControl position='bottom-right' />
        <GeolocateControl
          ref={geoControlRef}
          position='bottom-right'
          trackUserLocation={isMobile}
          showAccuracyCircle={isMobile}
          positionOptions={{ enableHighAccuracy: true }}
        />
        <ScaleControl />
        <StylesControl handleSetStyle={handleSetStyle} position='bottom-right'/>
        <Copyright bgcolor='hsla(0, 0%, 100%, .5)' left='50%' bottom={0} />
        <Slide
          in={isPositioning}
          timeout={transitionDuration}
          unmountOnExit
        >
          <Box
            position='absolute'
            top={isMobile ? '1rem' : '3rem'}
            width='100%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            zIndex={isMobile ? theme.zIndex.drawer - 1 : 99999}
          >
            <Chip label='Please select the new crash location' color='error' />
          </Box>
        </Slide>
        {isPositioning && <NewCrashSelector selectorTop={selectorTop} selectorLeft={selectorLeft} />}

        {newCrash && <CrashMarker crash={newCrash} handleCoordinates={handleCoordinates} />}

        {showCrashes && allCrashesMarkers}

        {popupInfo && <CrashPopup popupInfo={popupInfo} />}

        <GeneralInfo crashesSummary={crashesSummary} />

        {heatMapData && (
          <Source key='heatmap' type='geojson' data={heatMapData}>
            <Layer
              key='heatmap'
              {...heatmapLayer}
              layout={{ visibility: layersVisibility?.heatmap }}
            />
          </Source>
        )}
      </Map>
    </Box>
  )
}

// <Source key="boundaries" type="geojson" data={boundaries}>
//     <Layer key="boundaries"
//         {...boundaryStyle}
//         layout={{ visibility: layersVisibility["boundaries"] }} />
// </Source>
// <ControlPanel onChange={setLayersVisibility} />

// ControlPanel.js

// function ControlPanel (props) {
//   const [visibility, setVisibility] = useState({
//     heatmap: true
//     // , protectedSegments : true
//     // , majorRoads        : true
//   })

//   useEffect(() => {
//     // Convert true/false to "visible"/"none"
//     const visibilityState = Object.fromEntries(
//       Object.entries(visibility).map(([k, v]) => [k, v ? 'visible' : 'none'])
//     )
//     props.onChange(visibilityState)
//   }, [visibility])

//   const onVisibilityChange = (name, value) => {
//     setVisibility({ ...visibility, [name]: value })
//   }

//   return (
//         <div className={styles['control-panel']}>
//             <h3>Layers</h3>
//             <label><input type="checkbox"
//                 checked={visibility.boundaries}
//                 onChange={evt => onVisibilityChange('boundaries', evt.target.checked)}
//             /> Boundaries</label>
//         </div>
//   )
// }
