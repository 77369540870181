import { useRef, useState } from 'react'
import {
  ClickAwayListener
  , Fade
  , IconButton
  , MenuItem
  , MenuList
  , Paper
  , Popper
  , useTheme
} from '@mui/material'
import LayersIcon from '@mui/icons-material/Layers'

const positions = {
  'top-left'     : { top: 146, left: 10 }
  , 'top-right'    : { top: 146, right: 10 }
  , 'bottom-left'  : { bottom: 146, left: 10 }
  , 'bottom-right' : { bottom: 146, right: 10 }
}

export default function StylesControl ({ handleSetStyle, position }) {
  const theme = useTheme()
  const zIndex = theme.zIndex.drawer - 1
  const [open, setOpen] = useState(false)
  const buttonRef = useRef(null)
  const anchorEl = buttonRef.current
  // const [anchorEl, setAnchorEl] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleClick = event => {
    // setAnchorEl(anchorEl ? null : event.currentTarget)
    setOpen(prevOpen => !prevOpen)
  }

  const canBeOpen = open && Boolean(anchorEl)
  const id = canBeOpen ? 'transition-popper' : undefined

  const mapStyles = [
    { name: 'Map', style: 'openstreetmap' }
    , { name: 'Urban', style: 'streets' }
    , { name: 'Satellite', style: 'satellite' }
    , { name: 'Hybrid', style: 'hybrid' }
  ]

  return (
    <>
      <IconButton
        ref={buttonRef}
        sx={[
          positions[position]
          // { margin: '9.25rem 10px 0 0' }
          , { '&:hover': { backgroundColor: 'rgb(242 242 242)' } }
          , { position: 'absolute' }
          , { WebkitTapHighlightColor: 'rgb(0 0 0/0)' }
          , { WebkitTextSizeAdjust: '100%' }
          , { WebkitFontSmoothing: 'antialiased' }
          , { color: 'rgba(0, 0, 0, 0.87)' }
          , { pointerEvents: 'auto' }
          , { background: '#fff' }
          , { borderRadius: '4px' }
          , { float: 'right' }
          , { boxShadow: '0 0 0 2px rgba(0,0,0,.1)' }
          , { padding: '3px' }
          , { fontSize: '1.45rem' }
          , { lineHeight: '20px' }
          , { zIndex }
        ]}
        aria-describedby={id}
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select map layer"
        aria-haspopup="menu"
        disableRipple
        onClick={handleClick}
      >
        <LayersIcon fontSize='inherit' />
      </IconButton>
      <Popper
        id={id}
        sx={{ zIndex }}
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        placement='left'
        disablePortal
        modifiers={[
          {
            name    : 'offset'
            , options : {
              offset: [0, 10]
            }
          }
        ]}
      >
        {({ TransitionProps }) => (
          <Fade
            {...TransitionProps}
            style={{
              transformOrigin: 'right'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList id="popper-menu" autoFocusItem>
                  {mapStyles.map((style, index) => (
                    <MenuItem
                      key={style.style}
                      selected={index === selectedIndex}
                      onClick={() => {
                        setSelectedIndex(index)
                        handleSetStyle(mapStyles[index].style)
                        setOpen(false)
                      }}
                    >
                      {style.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}

/* WIP: Implementation that uses use control and custom control */
// import { useControl } from '@vis.gl/react-maplibre'
// import StylesControl from '@mapbox-controls/styles'
// import '@mapbox-controls/styles/src/index.css'

// const styles = [
//   {
//     label       : 'Streets'
//     , styleName : 'Maptiler streets'
//     , styleUrl  : `https://api.maptiler.com/maps/openstreetmap/style.json?key=${process.env.REACT_APP_API_KEY}`
//   }
//   , {
//     label       : 'Satellite'
//     , styleName : 'Maptiler satellite'
//     , styleUrl  : `https://api.maptiler.com/maps/satellite/style.json?key=${process.env.REACT_APP_API_KEY}`
//   }
// ]
// const mapStyles = [
//     { name: 'Map', style: 'openstreetmap' }
//     , { name: 'Urban', style: 'streets' }
//     , { name: 'Satellite', style: 'satellite' }
//     , { name: 'Hybrid', style: 'hybrid' }
//   ]
// {                 label: 'Streets'
//   , styleName : 'Mapbox Streets'
//   , styleUrl  : 'mapbox://styles/mapbox/streets-v12'
// }, {
//   label       : 'Satellite'
//   , styleName : 'Mapbox Satellite Streets'
//   , styleUrl  : 'mapbox://sprites/mapbox/satellite-streets-v12'
// }), 'top-left')

// or with compact view and default styles (streets and satellite)
// map.addControl(new StylesControl({ compact: true }), 'top-left')

// export default function StyleControl (props) {
//   useControl(() => new StylesControl({
//     styles
//     , compact: props.compact
//   }), {
//     position: props.position
//   })

//   return null
// }
