import { useState } from 'react'
import { useLingui } from '@lingui/react'
import { Trans, msg } from '@lingui/macro'
import { useForm, Controller } from 'react-hook-form'
import {
  Alert
  , Box
  , Button
  , Container
  , Grid
  , LinearProgress, Link
  , TextField,
  Typography
} from '@mui/material/'
import Copyright from '../components/Copyright'
import dayjs from 'dayjs'
import useHttp from '../hooks/useHttp'

// eslint-disable-next-line no-restricted-globals
const sendEmailUrl = new URL('/api/send_email', location)

const flag = `url('assets/${process.env.REACT_APP_FLAG}')`

const to = process.env.REACT_APP_ADMIN_ADDRESS
const object = 'Password reset request'

export default function ForgotPwd ({ isMobile }) {
  const { control, formState, handleSubmit } = useForm({ mode: 'onChange' })
  const { sendRequest, isLoading, error } = useHttp()
  const { _ } = useLingui()
  const [message, setMessage] = useState('')

  const onSubmit = async data => {
    if (Object.keys(formState.errors).length) return
    console.log(isLoading)

    const username = data.username
    const name = data.name
    const surname = data.surname
    const content = `On ${dayjs()} the user ${username || `${name} ${surname}`} requested a password reset. ADaMS.`

    console.log('Message', username, name, surname, content)

    sendRequest({
      url             : sendEmailUrl
      , httpMethod    : 'POST'
      , httpBody      : { content, to, object }
      , manageResData : () => {
        setMessage('An email has been sent to the administrator. You will be contacted soon.')
      }
    })
  }

  return (
    <Container component="main" maxWidth={isMobile ? 'xs' : 'sm'}>
      <Box
        mt={8}
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Box
          width='50%'
          mb='1rem'
          border='1px solid #c4c4c4'
          borderRadius={1}
          sx={{
            aspectRatio       : '3/2'
            , backgroundImage : flag
            , backgroundSize  : 'cover'
          }}>
        </Box>
        <Box width='50%'>
          <img src='../assets/logo_adams.png' alt='ADaMS logo' width='100%' />
        </Box>
        <Box
          width='100%'
          display='flex'
          flexDirection='column'
          alignItems='center'
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          mt='2rem'
        >
          <Typography textAlign='center' variant='h5'>
            <Trans>
              Please input your username to submit a reset password request to the Administrator.
            </Trans>
          </Typography>
          <Controller
            name='username'
            control={control}
            defaultValue=''
            render={({ field, fieldState }) => (
              <TextField
                margin={isMobile ? 'dense' : 'normal'}
                fullWidth
                label={_(msg`username`)}
                autoComplete='username'
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                {...field}
              />
            )}
            rules={{
              validate: (value, { name, surname }) => value.trim() !== '' || (name.trim() !== '' && surname.trim() !== '') || _(msg`Provide username or name AND surname`)
            }}
            // rules={{ required: _(msg`Username is required`) }}
          />
          <Typography mt={2} textAlign='center' variant='h5'>
            <Trans>
              Alternatively, if you don't remember your username, please input your name and surname.
            </Trans>
          </Typography>
          <Controller
            name='name'
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                margin={isMobile ? 'dense' : 'normal'}
                fullWidth
                label={_(msg`Name`)}
                autoComplete='name'
                autoFocus
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                {...field}
              />
            )}
            rules={{
              validate: (value, { username, surname }) => username.trim() !== '' || (value.trim() !== '' && surname.trim() !== '') || _(msg`Provide username or name AND surname`)
            }}
          />
          <Controller
            name='surname'
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                margin={isMobile ? 'dense' : 'normal'}
                fullWidth
                label={_(msg`Surname`)}
                autoComplete='surname'
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                {...field}
              />
            )}
            rules={{
              validate: (value, { username, name }) => username.trim() !== '' || (value.trim() !== '' && name.trim() !== '') || _(msg`Provide username or name AND surname`)
            }}
          />
          {isLoading &&
            <Box width='100%'>
              <LinearProgress />
            </Box>}
          {message && <Alert sx={{ mt: 2 }} severity='success'>{message}</Alert>}
          {error && <Alert sx={{ mt: 2 }} severity='error'>{error}</Alert>}
          <Button
            type="submit"
            fullWidth={isMobile}
            variant="contained"
            color='primary'
            sx={{ mt: 3, mb: 2 }}
          >
            <Trans>Submit</Trans>
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/" variant="body2">
                <Trans>Login</Trans>
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                <Trans>Sign Up</Trans>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mt={3} mb={2} display='flex' justifyContent='space-evenly' gap={2}>
        {/* <Box display='flex' alignItems='center' justifyContent='center' bgcolor='#4a4a4a' borderRadius={5} width={100} height={100} sx={{ transform: 'rotate(45deg)' }}> */}
        <Box display='flex' alignItems='center' justifyContent='center' width='50%'>
          <img src='../assets/logo_fred.png' alt='Fred logo' width='90%' />
        </Box>
        <Box display='flex' alignItems='center' justifyContent='center' width='50%'>
          <img src='#' alt="Sponsor" width='90%' height='90%' style={{ border: '1px solid #c4c4c4' }}/>
        </Box>
      </Box>
      <Box mt={4} display='flex' justifyContent='center'>
        <Copyright />
      </Box>
    </Container>
  )
}
