import { Trans } from '@lingui/macro'
import { Box, Typography } from '@mui/material'

export default function GeneralInfo ({ crashesSummary }) {
  return (
    <Box
      sx={{
        display        : 'flex'
        , background   : theme => theme.palette.background.default
        , border       : '2px solid rgba(0,0,0,0.2)'
        , borderRadius : '4px'
        , position     : 'absolute'
        , left         : '50%'
        , bottom       : '2rem'
        , transform    : 'translateX(-50%)'
        , zIndex       : theme => theme.zIndex.drawer - 1
      }}
    >
      <Box pl='2px' pr='4px' borderRight='2px solid rgba(0,0,0,0.2)'>
        <Typography variant='body2'>
          <Trans>Total crashes: {crashesSummary.total}</Trans>
        </Typography>
      </Box>
      <Box pl='2px' pr='4px'>
        <Typography variant='body2'>
          <Trans>Victims: {crashesSummary.fatal}</Trans>
        </Typography>
      </Box>
    </Box>
  )
}
