import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLingui } from '@lingui/react'
import { Trans, msg } from '@lingui/macro'
import {
  Alert
  , Box
  , Button
  , FormControlLabel
  , IconButton
  , InputAdornment
  , LinearProgress
  , MenuItem
  , styled
  , Switch
  , TextField
} from '@mui/material/'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import useHttp from '../../hooks/useHttp'
import { useForm, Controller } from 'react-hook-form'
import { MuiTelInput } from 'mui-tel-input'
import { ThemeContext } from '../../App'

// eslint-disable-next-line no-restricted-globals
const organizationsUrl = new URL('/api/organizations', location)

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width                       : 62
  , height                    : 34
  , padding                   : 7
  , '& .MuiSwitch-switchBase' : {
    margin            : 1
    , padding         : 0
    , transform       : 'translateX(6px)'
    , '&.Mui-checked' : {
      color                       : '#fff'
      , transform                   : 'translateX(22px)'
      , '& .MuiSwitch-thumb:before' : {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`
      }
      , '& + .MuiSwitch-track': {
        opacity         : 1
        , backgroundColor : theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be'
      }
    }
  }
  , '& .MuiSwitch-thumb': {
    backgroundColor : theme.palette.mode === 'dark' ? '#003892' : '#001e3c'
    , width           : 32
    , height          : 32
    , '&::before'     : {
      content            : "''"
      , position           : 'absolute'
      , width              : '100%'
      , height             : '100%'
      , left               : 0
      , top                : 0
      , backgroundRepeat   : 'no-repeat'
      , backgroundPosition : 'center'
      , backgroundImage    : `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff'
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`
    }
  }
  , '& .MuiSwitch-track': {
    opacity         : 1
    , backgroundColor : theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be'
    , borderRadius    : 20 / 2
  }
}))

export default function UserForm ({ isMobile, token, user }) {
  const { isLoading, error, sendRequest } = useHttp()
  const { _ } = useLingui()
  const { control, formState, handleSubmit, setValue, watch, reset } = useForm({ mode: 'onChange' })
  const passwordRef = useRef()
  const confirmPasswordRef = useRef()
  const [showOrg, setShowOrg] = useState(0)
  const [message, setMessage] = useState('')
  const [organizations, setOrganizations] = useState([])

  const handleMouseDownPassword = event => { event.preventDefault() }

  const watchOrganization = watch('organization')
  const watchNameAndSurname = watch(['name', 'surname'])
  const { mode, toggleTheme } = useContext(ThemeContext)

  const getOrganizations = useCallback(() => {
    sendRequest({
      url             : organizationsUrl
      , manageResData : res => {
        setOrganizations(res.organizations)
      }
    })
  }, [sendRequest])

  useEffect(() => {
    getOrganizations()
  }, [getOrganizations])

  useEffect(() => {
    if (user && Object.keys(user).length) reset(user)
  }, [user, reset])

  useEffect(() => {
    if (!user && watchNameAndSurname.every(field => field?.trim())) {
      setValue('username', watchNameAndSurname.join('.').toLowerCase())
    }
  }, [user, watchNameAndSurname, setValue])

  useEffect(() => {
    if (watchOrganization > 0) {
      setShowOrg(watchOrganization)
      if (watchOrganization === 1) setValue('hospital', '')
      if (watchOrganization === 2) setValue('department', '')
      if (watchOrganization === 3) setValue('company', '')
    }
  }, [watchOrganization, setValue])

  const handleShowOrg = org => {
    switch (org) {
      case 1:
        return { message: _(msg`Department/Unit`), error: _(msg`Department/Unit is required`) }
      case 2:
        return { message: _(msg`Hospital/Health care facility`), error: _(msg`Hospital/Health care facility is required`) }
      case 3:
        return { message: _(msg`Company`), error: _(msg`Company is required`) }
      default:
        break
    }
  }

  const onSubmit = async data => {
    if (Object.keys(formState.errors).length) return
    let body = {}
    let options = { httpMethod: 'POST' }
    if (user) {
      if (!Object.keys(formState.dirtyFields).length) return
      Object.keys(formState.dirtyFields)
        .forEach(key => {
          body = {
            ...body
            , [key]: key === 'theme'
              ? data[key]
                ? 'dark'
                : 'light'
              : data[key]
          }
        })
      options = {
        ...options
        , token
        // eslint-disable-next-line no-restricted-globals
        , url           : new URL('/api/user', location)
        , httpBody      : body
        , manageResData : res => {
          if (res.result.includes('success')) {
            setMessage(<Trans>User succefully modified!</Trans>)
          }
        }
      }
    } else {
      body = data
      options = {
        ...options
        // eslint-disable-next-line no-restricted-globals
        , url           : new URL('/api/register', location)
        , httpBody      : body
        , manageResData : res => {
          if (res.result.includes('success')) {
            setMessage(<Trans>
              User succefully recorded! Please <Link to='/'>Login</Link>
            </Trans>)
            reset()
          }
        }
      }
    }

    await sendRequest(options)
  }

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      alignItems='center'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      mt='2rem'
    >
      {token && <Controller
        name='theme'
        control={control}
        render={({ field, field: { onChange, mode: value } }) => (
          <FormControlLabel
            control={
              <MaterialUISwitch
                checked={value}
                onClick={e => {
                  onChange(e.target.checked) // Update RHF state
                  toggleTheme()
                }}
                margin={isMobile ? 'dense' : 'normal'}
                {...field}
              />}
            label={_(msg`Dark theme`)}
          />
        )}
      />}
      <Controller
        name='name'
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            margin={isMobile ? 'dense' : 'normal'}
            required={!token}
            fullWidth
            label={_(msg`Name`)}
            autoComplete='name'
            autoFocus
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          />
        )}
        rules={{ required: _(msg`Name is required`) }}
      />
      <Controller
        name='surname'
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            margin={isMobile ? 'dense' : 'normal'}
            required={!token}
            fullWidth
            label={_(msg`Surname`)}
            autoComplete='surname'
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          />
        )}
        rules={{ required: _(msg`Surname is required`) }}
      />
      <Controller
        name='organization'
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            margin={isMobile ? 'dense' : 'normal'}
            required={!token}
            disabled={token}
            fullWidth
            label={_(msg`Organization`)}
            autoComplete='organization'
            select
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          >
            {organizations.map(organization => (
              <MenuItem key={organization.id} value={organization.id}>
                {organization.description}
              </MenuItem>
            ))}
          </TextField>
        )}
        rules={{
          validate: (value, { hospital }) => !!value || !!hospital || _(msg`At least one of these fields is required`)
        }}
      />
      {[1, 2, 3].includes(showOrg) &&
        <Controller
          name='department'
          control={control}
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField
              margin={isMobile ? 'dense' : 'normal'}
              required={!token}
              fullWidth
              label={handleShowOrg(showOrg).message}
              autoComplete='department'
              error={!!fieldState.error}
              helperText={fieldState.error ? fieldState.error.message : null}
              {...field}
            />
          )}
          rules={{
            required: handleShowOrg(showOrg).error
          }}
        />
      }
      <Controller
        name='username'
        control={control}
        defaultValue=''
        render={({ field, fieldState }) => (
          <TextField
            margin={isMobile ? 'dense' : 'normal'}
            required={!token}
            disabled={token}
            fullWidth
            label={_(msg`username`)}
            autoComplete='username'
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          />
        )}
        rules={{ required: _(msg`Username is required`) }}
      />
      <Controller
        name='phone'
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <MuiTelInput
            // <TextField
            placeholder='+xxx xxxxxx'
            margin={isMobile ? 'dense' : 'normal'}
            required={!token}
            fullWidth
            label={_(msg`Telephone number`)}
            autoComplete='phone'
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          />
        )}
        rules={{
          required   : _(msg`Telephone number is required`)
          , pattern  : { value: /^\+[0-9 ]{6,16}$/, message: _(msg`Telephone number must be a number between 6 and 16 digits`) }
        }}
      />
      <Controller
        name='email'
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            margin={isMobile ? 'dense' : 'normal'}
            fullWidth
            label={_(msg`Email`)}
            autoComplete='email'
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          />
        )}
        rules={{ pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: _(msg`Email address is non valid`) } }}
      />
      <Controller
        name='password'
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            inputRef={passwordRef}
            margin={isMobile ? 'dense' : 'normal'}
            required={!token}
            fullWidth
            label={_(msg`New password`)}
            type={passwordRef.current?.type || 'password'}
            InputProps={{
              endAdornment: <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => {
                    passwordRef.current.type === 'text'
                      ? (passwordRef.current.type = 'password')
                      : (passwordRef.current.type = 'text')
                  }}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {passwordRef.current?.type === 'text' ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          />
        )}
        rules={{
          minLength   : { value: 8, message: _(msg`Password must be at least 8 characters`) }
          , pattern   : {
            value     : /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])./ // (?!.* ) no spaces
            , message : _(msg`Password must contain at least a lowercase letter, an upper case letter and a digit`)
          }
        }}
      />
      <Controller
        name='confirmPassword'
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            inputRef={confirmPasswordRef}
            margin={isMobile ? 'dense' : 'normal'}
            required={!token}
            fullWidth
            label={_(msg`Confirm new password`)}
            type={confirmPasswordRef.current?.type || 'password'}
            InputProps={{
              endAdornment: <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => {
                    confirmPasswordRef.current.type === 'text'
                      ? confirmPasswordRef.current.type = 'password'
                      : confirmPasswordRef.current.type = 'text'
                  }}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {confirmPasswordRef.current?.type === 'text' ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          />
        )}
        rules={{
          minLength : { value: 8, message: _(msg`Password must be at least 8 characters`) }
          , validate  : (value, { password }) => value.trim() === password.trim() || _(msg`Passwords must match`)
          , pattern   : {
            value   : /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])./ // (?!.* ) no spaces
            , message : _(msg`Password must contain at least an lowercase letter, an upper case letter and a digit`)
          }
          // validate   : (value, formValues) => bool || msg
          // formValues are actual form values by name, can be destructured
        }}
      />
      {isLoading && <LinearProgress sx={{ mt: 2 }} />}
      {error && <Alert sx={{ mt: 2 }} severity='error'>
        <Trans>There was an error: {error}.</Trans>
      </Alert>}
      {message && <Alert sx={{ mt: 2 }} severity='success'>
        {message}
      </Alert>}
      <Button
        type='submit'
        fullWidth={isMobile}
        variant='contained'
        color='primary'
        sx={{ mt: 3, mb: 2 }}
        disabled={token && !Object.keys(formState.dirtyFields).length}
      >
      {user ? <Trans>Save</Trans> : <Trans>Sign up</Trans>}
      </Button>
    </Box>
  )
}

// Example of validation schema using YUP
// const schema = yup.object().shape({
//   name              : yup.string().required(_(msg`Name is required`))
//   , surname         : yup.string().required(_(msg`Surname is required`))
//   , department      : yup.string().required()
//   , hospital        : yup.string().required(_(msg`Hospital/Health care facility is required`))
//   , phone           : yup.number(_(msg`Telephone number must be a number`)).positive().integer().required(_(msg`Telephone number is required`))
//   , password        : yup.string().required(_(msg`Password is required`))
//   , confirmPassword : yup.string().oneOf([yup.ref('password'), null], _(msg`Passwords must match`))
// })

// const { control, handleSubmit, setError, reset: resetForm } = useForm({
//   resolver: yupResolver(schema)
// })

// Solution to reset error on two related field simultaneously

// const watchEitherDeptOrHosp = watch(['department', 'hospital'])

// useEffect(() => {
//   if (watchEitherDeptOrHosp.some(field => field?.trim())) {
//     clearErrors(['department', 'hospital'])
//   }
// }, [watchEitherDeptOrHosp, clearErrors])

// Field validation
// rules={{
//   // validate: (value, { hospital }) => !!value || !!hospital || _(msg`At least one of these fields is required`)
// }}
// validate: (value, { department }) => !!value || !!department || _(msg`At least one of these fields is required`)
