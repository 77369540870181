import { Card, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import Pin from './maps/Pin'
import { SEVERITIES } from '../constants'

export default function Legend ({ isMobile }) {
  return (
    <Card sx={isMobile
      ? {
          zIndex   : theme => theme.zIndex.drawer + 1
          , position : 'absolute'
          , top      : theme => theme.mixins.toolbar.minHeight
          , right     : 0
          , mr       : '10px'
          , mt       : '10px'
        }
      : {
          zIndex    : theme => theme.zIndex.drawer + 1
          , position  : 'absolute'
          , bottom    : '50%'
          , right     : 0
          , mr        : '10px'
          , transform : 'translateY(50%)'
        }}>
      <List dense={isMobile} disablePadding={isMobile}>
        {SEVERITIES().map(severity => (
            <ListItem key={severity.text} disablePadding={isMobile} >
              <ListItemIcon sx={{ minWidth: '36px' }}>
                <Pin fill={severity.fill} />
              </ListItemIcon>
              <ListItemText primary={severity.text} sx={isMobile ? { paddingRight: '2px' } : {}} />
            </ListItem>
        ))}
      </List>
    </Card>
  )
}
