const MAX_ZOOM_LEVEL = 22

export const heatmapLayer = {
  id        : 'heatmap'
  , maxzoom : MAX_ZOOM_LEVEL
  , type    : 'heatmap'
  , paint   : {
    // Increase the heatmap weight based on frequency and property severity
    'heatmap-weight': [
      'interpolate'
      , ['linear']
      , ['get', 'Crash severity']
      , 0
      , 0
      , 4
      , 1
    ]
    // Increase the heatmap color weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    , 'heatmap-intensity': [
      'interpolate'
      , ['linear']
      , ['zoom']
      , 0
      , 0
      , MAX_ZOOM_LEVEL
      , 4
    ]
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparency color
    // to create a blur-like effect.
    , 'heatmap-color': [
      'interpolate'
      , ['linear']
      , ['heatmap-density']
      , 0
      , 'rgba(33, 102, 172, 0)'
      , 0.2
      , 'rgb(97, 97, 97)'
      , 0.4
      , 'rgb(9, 208, 7)'
      , 0.6
      , 'rgb(221, 221, 0)'
      , 0.8
      , 'rgb(221, 126, 0)'
      , 1
      , 'rgb(221, 0, 0)'
    ]
    // Adjust the heatmap radius by zoom level
    , 'heatmap-radius': [
      'interpolate'
      , ['linear']
      , ['zoom']
      , 1
      , 50
      , MAX_ZOOM_LEVEL
      , 150
    ]
    // Transition from heatmap to circle layer by zoom level
    , 'heatmap-opacity': [
      'interpolate'
      , ['linear']
      , ['zoom']
      , 1
      , 1
      , MAX_ZOOM_LEVEL
      , 0.1
    ]
  }
}
