import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { msg, t, Trans } from '@lingui/macro'
import {
  Box
  , Dialog
  , DialogTitle
  , DialogContent
  , DialogActions
  , IconButton
  , InputAdornment
  , TextField
  , Button
  , Stack
  , styled
  , Typography
} from '@mui/material'
import {
  GridRowModes
  , DataGrid
  , GridActionsCellItem
  , GridRowEditStopReasons
  , gridClasses
} from '@mui/x-data-grid'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import KeyIcon from '@mui/icons-material/Key'
import { useLingui } from '@lingui/react'

// Editable fields by user
// name
// surname
// department
// password
// is_verified

// Example user
// id: 1
// username: ""
// name: ""
// surname: ""
// organization: 0
// department: ""
// phone: "00000000"
// email: ""
// type: 1
// create_date: "2024-09-06"
// is_verified: false

const paginationModel = { page: 0, pageSize: 25 }

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  border                           : 0
  , [`& .${gridClasses.row}.even`] : {
    backgroundColor: theme.palette.grey[200]
  }
  , '& .MuiDataGrid-menuIcon > *': {
    color: theme.palette.primary.contrastText
  }
  , '& .MuiDataGrid-iconButtonContainer > *': {
    color: theme.palette.primary.contrastText
  }
}))

export default function UsersTable ({ usersRows, modifyUser }) {
  const location = useLocation()
  const organizations = location.state?.init?.organizations || {}
  const user = location.state?.init?.user || {}
  const [rows, setRows] = useState([])
  const [rowModesModel, setRowModesModel] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)
  const [userId, setUserId] = useState(0)
  const { _ } = useLingui()

  let typeOptions = []
  if (user.type === 1) {
    typeOptions = [
      { label: t`User`, value: 0 }
      , { label: t`Admin`, value: 1 }
      , { label: t`Organization admin`, value: 2 }
    ]
  }
  if (user.type === 2) {
    typeOptions = [
      { label: t`User`, value: 0 }
      , { label: t`Organization admin`, value: 2 }
    ]
  }

  useEffect(() => {
    if (usersRows.length) setRows(usersRows)
  }, [usersRows])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  // const handleDeleteClick = id => () => {
  //   setRows(rows.filter((row) => row.id !== id));
  // }

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel
      , [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find(row => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const PasswordChangeDialog = ({ id, open, onClose }) => {
    const passwordRef = useRef()
    const confirmPasswordRef = useRef()
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState('')

    const handleMouseDownPassword = event => { event.preventDefault() }

    const handleSubmit = e => {
      e.preventDefault()

      // Basic validation
      if (newPassword !== confirmPassword) {
        setError('Passwords must match')
        return
      }

      if (newPassword.length < 8) {
        setError('Password must be at least 8 characters long')
        return
      }

      if (!/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])./.test(newPassword)) {
        setError(_(msg`Password must contain at least a lowercase letter, an upper case letter and a digit`))
        return
      }

      // Call API to change the password
      console.log('New Password:', newPassword)
      modifyUser({ id, password: newPassword })

      // Reset form and close dialog
      setNewPassword('')
      setConfirmPassword('')
      setError('')
      onClose()
    }

    return (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Stack spacing={2} sx={{ mt: 2 }}>
                <TextField
                  inputRef={passwordRef}
                  label="New Password"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                  required
                  fullWidth
                  type={passwordRef.current?.type || 'password'}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => {
                        passwordRef.current.type === 'text'
                          ? (passwordRef.current.type = 'password')
                          : (passwordRef.current.type = 'text')
                      }}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {passwordRef.current?.type === 'text' ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                  }}
                />
                <TextField
                  inputRef={confirmPasswordRef}
                  label="Confirm New Password"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                  required
                  fullWidth
                  type={confirmPasswordRef.current?.type || 'password'}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => {
                          confirmPasswordRef.current.type === 'text'
                            ? confirmPasswordRef.current.type = 'password'
                            : confirmPasswordRef.current.type = 'text'
                        }}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {confirmPasswordRef.current?.type === 'text' ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }}
                />
                {error && (
                  <Typography color="error" variant="body2">
                    {error}
                  </Typography>
                )}
              </Stack>
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' color='error' onClick={onClose}>
              <Trans>Cancel</Trans>
            </Button>
            <Button type="submit" variant="outlined" color="success" onClick={handleSubmit}>
              <Trans>Change Password</Trans>
            </Button>
          </DialogActions>
        </Dialog>
    )
  }

  const handleOpenDialog = id => () => {
    setUserId(id)
    setDialogOpen(true)
  }

  const handleCloseDialog = () => setDialogOpen(false)

  const processRowUpdate = (newRow, oldRow) => {
    console.log('NEW', newRow, 'OLD', oldRow)
    let updatedRow = { isNew: false, id: newRow.id }
    Object.keys(newRow).forEach(key => {
      if (newRow[key] !== oldRow[key]) {
        updatedRow = { ...updatedRow, [key]: newRow[key] }
      }
    })
    modifyUser(updatedRow)
    setRows(rows.map(row => (row.id === newRow.id ? newRow : row)))
    return updatedRow
  }

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel)
  }

  const columns = [
    {
      field             : 'id'
      , headerName      : t`ID`
      , headerClassName : 'overload-theme--header'
      , flex            : 0.5 // , minWidth: 50
    }
    , {
      field             : 'username'
      , headerName      : t`Username`
      , headerClassName : 'overload-theme--header'
      , flex            : 1.5 // , minWidth: 150
    }
    , {
      field             : 'name'
      , headerName      : t`First name`
      , editable        : true
      , headerClassName : 'overload-theme--header'
      , flex            : 1 // , minWidth: 100
    }
    , {
      field             : 'surname'
      , headerName      : t`Last name`
      , editable        : true
      , headerClassName : 'overload-theme--header'
      , flex            : 1 // , minWidth: 100
    }
    , {
      field             : 'organization'
      , headerName      : t`Organization`
      , headerClassName : 'overload-theme--header'
      , flex            : 1
      , valueGetter     : value => {
        if (value) {
          return organizations.find(o => o.value === value)?.label
        }
      }
    }
    , {
      field             : 'departmentPol'
      , headerName      : t`Department/Unit`
      , headerClassName : 'overload-theme--header'
      , editable        : true
      , flex            : 1.25 // , minWidth: 125
      , valueGetter     : (_, row) => row.organization === 1 ? row.department : null
      , valueSetter     : (value, row) => ({ ...row, department: value || row.department })
    }
    , {
      field             : 'departmentHos'
      , headerName      : t`Hospital/Healthcare facility`
      , headerClassName : 'overload-theme--header'
      , editable        : true
      , flex            : 1.25 // , minWidth: 125
      , valueGetter     : (_, row) => row.organization === 2 ? row.department : null
      , valueSetter     : (value, row) => ({ ...row, department: value || row.department })
    }
    , {
      field             : 'departmentIns'
      , headerName      : t`Company`
      , headerClassName : 'overload-theme--header'
      , editable        : true
      , flex            : 1.25 // , minWidth: 125
      , valueGetter     : (_, row) => row.organization === 3 ? row.department : null
      , valueSetter     : (value, row) => ({ ...row, department: value || row.department })
    }
    , {
      field             : 'phone'
      , headerName      : t`Phone`
      , editable        : true
      , headerClassName : 'overload-theme--header'
      , flex            : 1 // , minWidth: 100
    }
    , {
      field             : 'email'
      , headerName      : t`Email`
      , editable        : true
      , headerClassName : 'overload-theme--header'
      , flex            : 1 // , minWidth: 100
    }
    , {
      field             : 'create_date'
      , headerName      : t`Date created`
      , headerClassName : 'overload-theme--header'
      , flex            : 1 // , minWidth: 100
    }
    , {
      field             : 'type'
      , headerName      : t`Role`
      , type            : 'singleSelect'
      , editable        : true
      , headerClassName : 'overload-theme--header'
      , flex            : 1 // , minWidth: 100
      , valueOptions    : typeOptions
    }
    , {
      field             : 'verified'
      , headerName      : t`Verified?`
      , type            : 'boolean'
      , editable        : true
      , headerClassName : 'overload-theme--header'
      , flex            : 0.75 // , minWidth: 75
    }
    , {
      field             : 'actions'
      , type            : 'actions'
      , headerName      : t`Actions`
      , headerClassName : 'overload-theme--header'
      , flex            : 1 // , minWidth: 100
      , cellClassName   : 'actions'
      , getActions      : ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label={t`Save`}
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />
            , <GridActionsCellItem
              icon={<CancelIcon />}
              label={t`Cancel`}
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={t`Edit`}
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
          , <GridActionsCellItem
            icon={<KeyIcon />}
            label={t`Change password`}
            className="textPrimary"
            onClick={handleOpenDialog(id)}
            color="inherit"
          />
        ]
      }
    }
  ]

  return (
    <Box
      width='100%'
      sx={theme => ({
        '& .overload-theme--header': {
          backgroundColor   : theme.palette.primary.main
          , color           : theme.palette.primary.contrastText
        }
      })}
    >
      <StripedDataGrid
        showColumnVerticalBorder
        showCellVerticalBorder
        rows={rows}
        columns={columns}
        editMode="row"
        isCellEditable={params => params.row.type !== 1}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={error => error}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[10, 25, 50]}
        getRowClassName={params => {
          return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }}
      />
      <PasswordChangeDialog id={userId} open={dialogOpen} onClose={handleCloseDialog} />
    </Box>
  )
}
